.checkout-root-container {
  height: 100%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-container {
    margin-bottom: 48px;
  }

  .content {
    position: relative;
    min-width: 800px;
    min-height: 60vh;
    padding: 48px 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--light-gray);
    border-radius: 2px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

    .payment-info-col {
      flex: 1;

      .title {
        margin-bottom: 24px;
        color: var(--gray);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
      }

      .payment-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .paymentbox {
          width: 100%;

          .button {
            width: 100%;
            margin-top: 36px;
            font-weight: 600;
            border-radius: 8px;
            background-color: var(--primary);
          }

          .stripe-error {
            margin-top: var(--stripe-p-spacing-1);
            color: var(--stripe-color-danger);
            font-size: var(--stripe-font-size-sm);
            font-family: var(--stripe-font-family);
            font-weight: var(--stripe-font-weight-normal);
          }
        }
      }
    }

    .divider {
      height: calc(100% + 40px);
      width: 1px;
      margin: -20px 36px;
      background-color: var(--light-gray);
    }

    .explanation-col {
      flex: 1;
      height: 100%;

      .explanation-title {
        color: var(--gray);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
      }

      .explanation-product {
        margin: 24px 0;
        font-size: 18px;
        font-weight: 500;
      }

      .explanation-price,
      .explanation-coupon {
        font-size: 14px;

        .price-label {
          margin: 0 4px;
          font-size: 24px;
        }
      }

      .explanation-price {
        margin-bottom: 20px;
      }

      .explanation-coupon {
        margin-bottom: 4px;
      }
    }

    .loading-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--white);
    }
  }
}
