.thank-you-root-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-text {
    margin-top: 108px;
    margin-bottom: 48px;
    font-size: 24px;
    font-weight: 500;
  }

  .subtitle-text {
    font-size: 18px;
  }
}

.thank-you-loading {
  left: 0;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;

  .loader {
    position: absolute;
    left: calc(50% - var(--spinner-size) / 2);
    top: calc(50% - var(--spinner-size) / 2);
  }
}
