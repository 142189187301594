.sign-up-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;

  .auth-contents {
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth-square-title {
      z-index: 1;
      text-align: center;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .logo-container {
      width: 382px;
      margin-bottom: 40px;
    }

    .auth-square {
      height: 446px;
      width: 382px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

      .auth-logo {
        width: 70%;
      }

      .auth-inputs {
        width: 322px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .inputs-row {
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input + .input {
            margin-top: 0;
            margin-left: 16px;
          }
        }
      }

      .auth-button {
        height: 40px;
        width: 322px;
        margin-top: 30px;
        background-color: var(--primary-color);
        color: #fff;
        outline: none;
        border: none;
        border-radius: 2px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        font-weight: 700;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:active {
          opacity: 0.9;
        }
      }

      .rs-input-error + .auth-button {
        margin-top: 8px;
      }

      .redirect {
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        color: var(--primary);
        user-select: none;
        text-decoration: none;

        &:active {
          opacity: 0.2;
        }

        .auth-link {
          margin-left: 8px;
          font-weight: 800;
        }
      }
    }
  }
}
