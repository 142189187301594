body {
  margin: 0;
  overscroll-behavior: none;
  --spinner-size: 80px;
}

.main-content {
  height: 100%;
  width: 100%;
}

.loader-container {
  left: 0;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .loader {
    position: absolute;
    left: calc(50% - var(--spinner-size) / 2);
    top: calc(50% - var(--spinner-size) / 2);
  }
}
