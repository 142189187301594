.terms-root-container {
  height: 100%;
  padding-bottom: 0;
  overflow-y: hidden;

  .content {
    height: calc(100% - 204px);
    padding: 16px;
    overflow-y: scroll;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: var(--primary);
      font-weight: 600;

      .logo {
        max-width: 30%;
        height: auto;
        object-fit: contain;
      }
    }

    .thankyou {
      flex: 1;

      .title {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.25px;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        margin-top: 20px;
        text-align: center;
      }

      .text {
        padding: 0;
        margin: 0;
        margin-bottom: 24px;
        text-align: left;
      }

      .button {
        margin-top: 24px;
        width: 100%;
        background-color: var(--primary);
      }
    }
    .payment-container {
      display: flex;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      justify-content: center;

      .paymentbox {
        width: 33%;

        .button {
          width: 100%;
          // align-self: center;
          // justify-content: center;
          font-weight: 600;
          font-size: 12px;
          border-radius: 8px;
          background-color: var(--primary);
          margin-top: 12px;
        }
      }
    }
  }
}
